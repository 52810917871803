

.App {
	color: var(--green);
	cursor: move;
	line-height: 1.3;
	letter-spacing: 0.02em;
	font-family: 'Standard Web', sans-serif;
	font-size: 15px;
}

a {
	color: currentColor;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	text-decoration-color: var(--highlight);
	cursor: pointer;
}
a:hover {
	text-decoration-color: var(--green);
}
html {
	--padding: 30px;
	--grey: #3c3c3c;
	--background: ghostwhite;
	--green: #00aa6e;
	--highlight: #d7d7d7;
	--header-height: 49px;
	--sidebar-width: 310px;
}

* {
	box-sizing: border-box;
}

h1 {
	font-weight: normal;
	margin: 0 0 1rem 0;
}

.about__toggle {
	cursor: pointer;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	text-decoration-color: var(--highlight);
	position: absolute;
	left: var(--padding);
}

.about__toggle:hover,
.about__close:hover {
	text-decoration-color: var(--green);
}

path.country {
	pointer-events: none;
}

polygon:hover {
	fill: #000;
	cursor: pointer;
}

header {
	cursor: auto;
	top: 0;
	right: 0;
	left: 0;
	height: var(--header-height);
	z-index: 3;
	position: fixed;
	border-bottom: 1px solid var(--highlight);
	display: flex;
	justify-content: space-between;
	padding: 0 var(--padding);
	align-items: center;
	background: var(--background);
}

.external {
	position: absolute;
	right: var(--padding);
}

.external span {
	/*position: relative;
	top: 2px;*/
	padding-left: 3px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

header > div:nth-child(2) {
	text-align: center;
	width: 100%;
	font-size: 18px;
}

.about {
	position: fixed;
	top: var(--header-height);
	left: var(--sidebar-width);
	z-index: 3;
	right: 0;
	bottom: 0;
	padding: calc(var(--padding) * 2);
	overflow: auto;
	cursor: auto;
	background-color: var(--background);
}

.about__close {
	position: absolute;
	right: var(--padding);
	top: 18px;
	text-decoration: underline;
	cursor: pointer;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	text-decoration-color: var(--highlight);
}

.hero {
	margin-bottom: 2rem;
	
}

.hero__image {
	height: 380px;
}
.hero img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}

.about {
	font-size: 16px;
	line-height: 1.5;
}

p.hero__caption {
	text-align: center;
	color: var(--highlight);
}

.about__inner {

	max-width: 650px;
}

.recording__list {
	background: var(--background);
	position: fixed;
	z-index: 4;
	padding: 0 0 0 0;
	width: var(--sidebar-width);
	top: var(--header-height);
	bottom: 0;
	overflow: auto;
	border-right: 1px solid var(--highlight);
}
.recording__list > div {
	position: relative;
	cursor: pointer;
	padding: 6px 0 4px var(--padding);
	border-bottom: 1px solid var(--highlight);	
}



.recording__list svg {
	position: absolute;
	width: 10px;
	left: 8px;
	top: 17px;
	fill: #4562f5;
	display: none;
}

line {
	stroke: #00aa6e;
}


.recording__list > div:nth-last-child(1) {
	border-bottom: none;
}

.core-contributor svg {
	display: block;
}

.recording__list > div:hover {
	background: #ebebeb;

}
.recording__list > div:hover svg {
	
}

#rastermap {
	z-index: 0;
	position: relative;
	top: 0;
	left: 0;
	filter: hue-rotate(27deg);
}
.decoded {
	margin-top: 1rem;
}
.recording__inner .time {
	margin-bottom: 2px;
	display: block;
}
.recording__inner {
	line-height: 1.35;
}

.recording__imagesquestions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--padding);
}

.recording__images img {
	width: 100%;
	display: block;
}
.recording__questions,
.recording__images {
	margin-top: 1rem;
	
}

p {
	margin: 0 0 1rem 0;
}
h4 {
	margin: 0 0 0 0;

}

.location {

}
.time {
	font-family: 'Menlo', monospace;
	font-size: 13px;
	color: var(--grey);
}

#rastermap img {
	width: 100%;
	height: auto;
	display: block;
}

#vectormap {

	z-index: 1;
	position: absolute;
	top: 0;
	left: 12px;
	/* pointer-events: none; */
}

.recording__details svg {
	display: none;
}
.recording__details {

}

#map-container {
	/* pointer-events: none; */
}

.recording__details {

	z-index: 2;
	background: var(--background);
	overflow: auto;
	padding: 15px;
	position: absolute;
	width: 50vw;
	height: 50vh;
	margin-top: 1px;
	margin-left: 1px;
}

.recording__details img {
	width: 100%;
}

.recording__close {
	display: none;
}
strong {
	font-weight: bold;
}

@media (max-width: 700px){
	#map-container {
		width: 10912px;
		height: 5311px;
	}
	html {

		--header-height: auto;
	}
	.recording__list {
		display: none;
	}	
	.recording__close {
		display: block;
		position: absolute;
		top: 2px;
		right: 10px;
		font-size: 29px;
	}
	.about {
		left: 0;
		top: 75px;
		padding: 15px 20px 20px 20px;
		width: 100vw;
	}
	h1 {
		line-height: 1.1;
		font-size: 30px;
	}
	body {
		/* width: 100vw;
		height: 100vh; */
	}
	.recording__details {
		height: 70vh;
		width: calc(100vw - 22px);
	}
	.recording__imagesquestions {
		display: block;
	}
	header {
		width: 100%;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-auto-flow: dense;
	}
	.about__toggle {
		padding-left: 15px;
	}
	.about__close {
		position: relative;
		text-align: right;
		right: auto;
		top: auto;
		padding-bottom: 10px;
	}
	header > div:nth-child(3){
		text-align: right;
		padding-right: 15px;

	}
	header > div:nth-child(2){
		padding-top: 9px;
		padding-bottom: 7px;
		grid-column: span 2;
		font-size: 1rem;
		text-align: left;
		padding-left: 15px;
		border-top: 1px solid var(--highlight);
	}
	header span {
		display: none;
	}
	.about__toggle, .external {
		padding-top: 9px;
		padding-bottom: 9px;
		position: relative;
		left: auto;
		right: auto;
	}
}