body {
	margin: 0;
	font-family: sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #000;
	overflow: auto;
	width: 10912px;
	height: 5311px;
}

@font-face {
	font-family: 'Standard Web';
	src: url('./fonts/standard-bold-italic-webfont.woff2') format('woff2'),
		 url('./fonts/standard-bold-italic-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Standard Web';
	src: url('./fonts/standard-bold-webfont.woff2') format('woff2'),
		 url('./fonts/standard-bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Standard Web';
	src: url('./fonts/standard-book-italic-webfont.woff2') format('woff2'),
		 url('./fonts/standard-book-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'Standard Web';
	src: url('./fonts/standard-book-webfont.woff2') format('woff2'),
		 url('./fonts/standard-book-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
}